import API from "../services/services";

class ManageDroppingService {
  getAll(params) {
    return API.get("blood_dropping/list", { params });
  }
  getDetail(id) {
    return API.get(`blood_dropping/detail/${id}`);
  }
  add(payload) {
    return API.post("blood_dropping/store", payload);
  }
  getDaily(params) {
    return API.get("blood_dropping/get_daily_report", { params });
  }
  checkingBarcode(barcode) {
    return API.get("blood_dropping/check/" + barcode);
  }
}

export default new ManageDroppingService();
