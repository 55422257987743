<template>
  <div class="bg-black-0 rounded p-3 shadow-sm">
    <h1>Rekapitulasi Dropping Darah</h1>
    <div class="d-flex justify-content-between mt-5">
      <div class="d-flex" style="gap: 0.5rem">
        <b-form-select
          v-model="filter.selectedMonth"
          :options="months"
          value-field="value"
          text-field="text"
          placeholder="Pilih Bulan"
          @change="fetchData"
        />

        <b-form-select
          v-model="filter.selectedYear"
          :options="years"
          value-field="value"
          text-field="text"
          placeholder="Pilih Tahun"
          @change="fetchData"
        />
      </div>
      <!-- <div class="ml-3">
        <b-input-group>
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            class="border-left-0"
            v-model="table.search"
            type="search"
            placeholder="Search"
            @input="debounceSearch"
          />
        </b-input-group>
      </div> -->
    </div>
    <div class="">
      <p class="mt-3 mb-1">semua ({{ table.total }})</p>
    </div>
    <b-table
      striped
      hover
      small
      :items="table.items"
      :fields="table.fields"
      responsive
    >
      <template #cell(action)="row">
        <b-button
          size="sm"
          @click="row.toggleDetails"
          class="mr-2"
          variant="outline-primary"
        >
          {{ row.detailsShowing ? "Collapse" : "Expand" }}
        </b-button>
      </template>
      <template #row-details="row">
        <div class="bg-white p-3 d-flex flex-wrap justify-content-around">
          <table
            class="table table-bordered table-striped table-hover table-sm mb-0 rounded mb-3 text-center"
            style="width: 400px"
            v-for="(detail, index) in row.item.detail"
            :key="index"
          >
            <tr>
              <th class="text-center" colspan="5">{{ detail.date }}</th>
            </tr>
            <tr>
              <th>Komponen</th>
              <th>A Pos</th>
              <th>B Pos</th>
              <th>AB Pos</th>
              <th>O Pos</th>
            </tr>
            <tr>
              <th class="text-left">PRC</th>
              <td>{{ detail.PRC?.["A Pos"] || 0 }}</td>
              <td>{{ detail.PRC?.["B Pos"] || 0 }}</td>
              <td>{{ detail.PRC?.["AB Pos"] || 0 }}</td>
              <td>{{ detail.PRC?.["O Pos"] || 0 }}</td>
            </tr>
            <tr>
              <th class="text-left">LP</th>
              <td>{{ detail.LP?.["A Pos"] || 0 }}</td>
              <td>{{ detail.LP?.["B Pos"] || 0 }}</td>
              <td>{{ detail.LP?.["AB Pos"] || 0 }}</td>
              <td>{{ detail.LP?.["O Pos"] || 0 }}</td>
            </tr>
            <tr>
              <th class="text-left">TC</th>
              <td>{{ detail.TC?.["A Pos"] || 0 }}</td>
              <td>{{ detail.TC?.["B Pos"] || 0 }}</td>
              <td>{{ detail.TC?.["AB Pos"] || 0 }}</td>
              <td>{{ detail.TC?.["O Pos"] || 0 }}</td>
            </tr>
            <tr>
              <th>Jumlah</th>
              <td>
                {{
                  (detail.PRC?.["A Pos"] || 0) +
                  (detail.LP?.["A Pos"] || 0) +
                  (detail.TC?.["A Pos"] || 0)
                }}
              </td>
              <td>
                {{
                  (detail.PRC?.["B Pos"] || 0) +
                  (detail.LP?.["B Pos"] || 0) +
                  (detail.TC?.["B Pos"] || 0)
                }}
              </td>
              <td>
                {{
                  (detail.PRC?.["AB Pos"] || 0) +
                  (detail.LP?.["AB Pos"] || 0) +
                  (detail.TC?.["AB Pos"] || 0)
                }}
              </td>
              <td>
                {{
                  (detail.PRC?.["O Pos"] || 0) +
                  (detail.LP?.["O Pos"] || 0) +
                  (detail.TC?.["O Pos"] || 0)
                }}
              </td>
            </tr>
          </table>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import manageDroppingAPI from "../../../api/dropping/manageDroppingAPI";
import { debounce } from "lodash";

export default {
  name: "RekapDropping",
  components: {
    BFormSelect,
    BTable,
    BButton,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      filter: {
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
      },
      months: [
        { value: null, text: "Pilih Bulan" },
        { value: 1, text: "Januari" },
        { value: 2, text: "Februari" },
        { value: 3, text: "Maret" },
        { value: 4, text: "April" },
        { value: 5, text: "Mei" },
        { value: 6, text: "Juni" },
        { value: 7, text: "Juli" },
        { value: 8, text: "Agustus" },
        { value: 9, text: "September" },
        { value: 10, text: "Oktober" },
        { value: 11, text: "November" },
        { value: 12, text: "Desember" },
      ],
      years: [
        { value: null, text: "Pilih Tahun" },
        ...(() => {
          const years = [];
          const currentYear = new Date().getFullYear();
          for (let i = 2023; i <= currentYear; i++) {
            years.push({ value: i, text: i });
          }
          return years;
        })(),
      ],
      table: {
        search: "",
        loading: false,
        total: 0,
        fields: [
          { key: "hospital", label: "Rumah Sakit" },
          { key: "prc", label: "PRC" },
          { key: "lp", label: "LP" },
          { key: "tc", label: "TC" },
          { key: "total", label: "Total" },
          { key: "action", label: "Aksi" },
        ],
        items: [],
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const { data: response } = await manageDroppingAPI.getDaily({
          month: this.filter.selectedMonth,
          year: this.filter.selectedYear,
          search: this.table.search,
        });
        this.table.items = response.data.map((item) => ({
          ...item,
          prc: item.prc || 0, // Set prc to 0 if not available
          lp: item.lp || 0, // Set lp to 0 if not available
          tc: item.detail.reduce(
            (sum, detail) =>
              sum +
              (detail.TC
                ? Object.values(detail.TC).reduce((a, b) => a + b, 0)
                : 0),
            0
          ), // Calculate tc based on detail data or set it to 0 if not present
        }));

        this.table.total = response.data.length;
      } catch (error) {
        this.$bvToast.toast("Data Tidak Tersedia", {
          title: "Terjadi kesalahan",
          variant: "danger",
          solid: true,
        });
        this.table.items = [];
      } finally {
        this.table.loading = false;
      }
    },

    debounceSearch() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  created() {
    this.debounceSearch = debounce(this.debounceSearch, 500);
  },
};
</script>
